<template>
  <div class="shopping-cart">
    <!-- Title -->
    <h1 class="hty-heading">{{ $t('enduser.products.orderSummary') }}</h1>

    <!-- Table -->
    <div class="table mt-3">
      <!-- Header -->
      <div class="header row">
        <div
          :class="['header-column', `col-${key.space}`]"
          v-for="(key, i) in columns"
          :key="i"
        >
          {{ key.name }}
        </div>
      </div>

      <!-- Body -->
      <div class="body">
        <div
          class="item row"
          v-for="(item, i) in $store.getters.cartItems"
          :key="i"
        >
          <div class="image col-3">
            <div class="text-orange font-weight-bold mb-1">
              {{ item.item.name }}
            </div>
            <img
              style="border-radius: 10px"
              :src="item.item.images | resolveImagesJson('160x130')"
            />
          </div>

          <div class="package col-3">
            <div class="field">
              <div>{{ $t('enduser.products.width') }}:</div>
              <div>-</div>
            </div>
            <div class="field">
              <div>{{ $t('enduser.products.height') }}:</div>
              <div>-</div>
            </div>
            <div class="field">
              <div>{{ $t('enduser.products.depth') }}:</div>
              <div>-</div>
            </div>
          </div>

          <div class="price col-2">{{ item.item.price | EUR }}</div>

          <div class="quantity col-2">
            <div>
              <CartCounter :item="item" />
            </div>
          </div>
          <div class="total col-2">
            {{ item.price | EUR }}

            <!-- X Icon -->
            <ButtonElement
              @click="removeItem(item)"
              link
              style="position: absolute; top: 80px; right: 10px"
            >
              <XIcon />
            </ButtonElement>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="info">
        {{ llorem }}
      </div>
      <div class="summary">
        <ButtonElement
          :orange-secondary="true"
          :anchor="{ name: 'ProductsCategories' }"
        >
          {{ $t('enduser.products.backToShopping') }}
        </ButtonElement>

        <div class="subtotal">
          <!-- <div class="discount">
            <div>{{ $t('enduser.products.firstOrderDiscount') }}:</div>
            <div>10%</div>
          </div> -->
          <div class="amount">
            <div>{{ $t('enduser.products.subtotal') }}:</div>
            <div>{{ $store.getters.cart.totalPrice | EUR }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer buttons -->
    <div class="actions">
      <ButtonElement :orange-secondary="true" @click="cancel">
        {{ $t('buttons.cancel') }}
      </ButtonElement>
      <ButtonElement :orange="true" @click="confirm">
        {{ $t('buttons.confirm') }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import CartCounter from '@/components/ShoppingCart/ShoppingCartCounter';

export default {
  components: {
    CartCounter,
  },

  data() {
    return {
      llorem: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Non sit vitae, ultrices justo tortor, ac nisi, leo orci.
        Turpis commodo sed dui aenean lectus ut. Odio pulvinar odio
        quisque massa fermentum vitae. Vitae nulla arcu fringilla ut
        lobortis amet sagittis. Eget facilisi sed venenatis tortor purus
        nibh in. Nisi id eu euismod orci euismod blandit justo libero.`,
      columns: [
        {
          name: this.$t('enduser.products.product'),
          space: '3',
        },
        {
          name: this.$t('enduser.products.packageDimension'),
          space: '3',
        },
        {
          name: this.$t('enduser.products.price'),
          space: '2',
        },
        {
          name: this.$t('enduser.products.quantity'),
          space: '2',
        },
        {
          name: this.$t('enduser.products.total'),
          space: '2',
        },
      ],
    };
  },

  methods: {
    cancel() {
      this.$store.dispatch('clearCart');
    },
    confirm() {
      //
    },
    removeItem(item) {
      this.$store.dispatch('cartRemoveItem', item.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.shopping-cart {
  position: relative;
}
.table {
  .header {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 15px;
    border-bottom: 1px solid #d4d4d4;
  }
  .header-column {
    font-weight: bold;
    font-size: 20px;
    color: #2b2b2b;
  }
  .item {
    display: flex;
    justify-content: space-evenly;
    height: 180px;
    border-bottom: 1px solid #d4d4d4;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .image {
    img {
      width: 160px;
      height: 130px;
    }
  }
  .price,
  .total {
    font-weight: 500;
    font-size: 16px;
    color: #e58413;
  }
  .package {
    flex-direction: column;

    .field {
      display: flex;
      padding-bottom: 10px;
      div {
        font-weight: 500;
        font-size: 16px;
      }
      div:nth-child(1) {
        color: #e58413;
      }
      div:nth-child(2) {
        color: #2b2b2b;
        margin-left: 10px;
      }
    }
  }
}
.footer {
  .info {
    padding: 30px 5px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: flex-end;
    color: #bfbfbf;
  }
  .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .subtotal {
    div {
      display: flex;
    }
    .discount {
      margin-bottom: 20px;
      > div {
        font-weight: 500;
        font-size: 16px;
        color: #2b2b2b;
      }
      div:last-child {
        margin-left: 10px;
      }
    }
    .amount {
      align-items: center;
      div:first-child {
        font-weight: 500;
        font-size: 16px;
        color: #2b2b2b;
      }
      div:last-child {
        margin-left: 10px;
        font-weight: 500;
        font-size: 20px;
        color: #e58413;
      }
    }
  }
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  button {
    width: 33%;
    justify-content: center;
  }
}
</style>
