<template>
  <div class="contact-container" style="padding: 1.5rem 2rem">
    <h2 class="head">Schreib uns!</h2>
    <div class="row">
      <!-- Cards -->
      <div class="col-lg-6" style="display: flex; margin-top: 1.5rem">
        <!-- Contact information card -->
        <div class="card-custom" style="position: relative">
          <h2 class="heading">Kontakt Informationen</h2>

          <!-- Social links -->
          <!--<div style="position: absolute; top: 1.5rem; right: 1.5rem">
          <a href="https://www.instagram.com/hoodty_official/" target="_blank">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                stroke="#E58413"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 11.3703C16.1234 12.2025 15.9812 13.0525 15.5937 13.7993C15.2062 14.5461 14.5931 15.1517 13.8416 15.53C13.0901 15.9082 12.2384 16.0399 11.4077 15.9062C10.5771 15.7726 9.80971 15.3804 9.21479 14.7855C8.61987 14.1905 8.22768 13.4232 8.09402 12.5925C7.96035 11.7619 8.09202 10.9102 8.47028 10.1587C8.84854 9.40716 9.45414 8.79404 10.2009 8.40654C10.9477 8.01904 11.7977 7.87689 12.63 8.0003C13.4789 8.12619 14.2648 8.52176 14.8716 9.12861C15.4785 9.73545 15.8741 10.5214 16 11.3703Z"
                stroke="#E58413"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 6.5H17.51"
                stroke="#E58413"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/hoodtyofficial"
            target="_blank"
            style="display: inline-block; margin-left: 2rem"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
                stroke="#E58413"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <a
            href="https://twitter.com/hoodty_official"
            target="_blank"
            style="display: inline-block; margin-left: 2rem"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 3.00029C22.0424 3.67577 20.9821 4.1924 19.86 4.53029C19.2577 3.8378 18.4573 3.34698 17.567 3.12422C16.6767 2.90145 15.7395 2.95749 14.8821 3.28474C14.0247 3.612 13.2884 4.19469 12.773 4.95401C12.2575 5.71332 11.9877 6.61263 12 7.53029V8.53029C10.2426 8.57586 8.50127 8.1861 6.93101 7.39574C5.36074 6.60537 4.01032 5.43893 3 4.00029C3 4.00029 -1 13.0003 8 17.0003C5.94053 18.3983 3.48716 19.0992 1 19.0003C10 24.0003 21 19.0003 21 7.50029C20.9991 7.22174 20.9723 6.94388 20.92 6.67029C21.9406 5.66378 22.6608 4.393 23 3.00029V3.00029Z"
                stroke="#E58413"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        -->
          <p class="text-primary" style="margin-bottom: 1.5rem">
            <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. -->
          </p>

          <div class="contact-info" style="margin-top: 3rem">
            <!-- Phone -->
            <div class="contact-info-box">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.2"
                  width="60"
                  height="60"
                  rx="10"
                  fill="#FFC076"
                />
                <path
                  d="M39.9999 34.9201V37.9201C40.0011 38.1986 39.944 38.4743 39.8324 38.7294C39.7209 38.9846 39.5572 39.2137 39.352 39.402C39.1468 39.5902 38.9045 39.7336 38.6407 39.8228C38.3769 39.912 38.0973 39.9452 37.8199 39.9201C34.7428 39.5857 31.7869 38.5342 29.1899 36.8501C26.7738 35.3148 24.7253 33.2663 23.1899 30.8501C21.4999 28.2413 20.4482 25.2711 20.1199 22.1801C20.0949 21.9036 20.1278 21.6249 20.2164 21.3617C20.3051 21.0986 20.4475 20.8568 20.6347 20.6517C20.8219 20.4466 21.0497 20.2828 21.3037 20.1706C21.5577 20.0584 21.8323 20.0004 22.1099 20.0001H25.1099C25.5952 19.9953 26.0657 20.1672 26.4337 20.4836C26.8017 20.8001 27.042 21.2395 27.1099 21.7201C27.2366 22.6802 27.4714 23.6228 27.8099 24.5301C27.9445 24.888 27.9736 25.277 27.8938 25.651C27.8141 26.0249 27.6288 26.3682 27.3599 26.6401L26.0899 27.9101C27.5135 30.4136 29.5864 32.4865 32.0899 33.9101L33.3599 32.6401C33.6318 32.3712 33.9751 32.1859 34.3491 32.1062C34.723 32.0264 35.112 32.0556 35.4699 32.1901C36.3772 32.5286 37.3199 32.7635 38.2799 32.8901C38.7657 32.9586 39.2093 33.2033 39.5265 33.5776C39.8436 33.9519 40.0121 34.4297 39.9999 34.9201Z"
                  stroke="#E58413"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="text-primary">0173 / 1946839</span>
            </div>

            <!-- Mail -->
            <div class="contact-info-box">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.2"
                  width="60"
                  height="60"
                  rx="10"
                  fill="#FFC076"
                />
                <path
                  d="M22 23H38C39.1 23 40 23.8137 40 24.8082V35.6574C40 36.6519 39.1 37.4656 38 37.4656H22C20.9 37.4656 20 36.6519 20 35.6574V24.8082C20 23.8137 20.9 23 22 23Z"
                  stroke="#E58413"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40 24.8086L30 31.1373L20 24.8086"
                  stroke="#E58413"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <a href="mailto:info@hoodty.com">
                <span class="text-primary">info@hoodty.com</span>
              </a>
            </div>

            <!-- Address -->
            <div class="contact-info-box">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.2"
                  width="60"
                  height="60"
                  rx="10"
                  fill="#FFC076"
                />
                <path
                  d="M39.875 28.416C39.875 35.7077 30.5 41.9577 30.5 41.9577C30.5 41.9577 21.125 35.7077 21.125 28.416C21.125 25.9296 22.1127 23.545 23.8709 21.7869C25.629 20.0287 28.0136 19.041 30.5 19.041C32.9864 19.041 35.371 20.0287 37.1291 21.7869C38.8873 23.545 39.875 25.9296 39.875 28.416Z"
                  stroke="#E58413"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.5 31.541C32.2259 31.541 33.625 30.1419 33.625 28.416C33.625 26.6901 32.2259 25.291 30.5 25.291C28.7741 25.291 27.375 26.6901 27.375 28.416C27.375 30.1419 28.7741 31.541 30.5 31.541Z"
                  stroke="#E58413"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span class="text-primary">Vorbruch 72D, 41334 Nettetal</span>
            </div>
          </div>

          <p class="text-secondary">
            <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas eu,
          lectus eget ornare nunc. Nisl id vitae vitae metus, volutpat vitae.
          Sed dui in aenean duis nibh. Enim mi, risus duis viverra porttitor a
          donec amet. -->
          </p>
        </div>
      </div>

      <!-- Get in touch card -->
      <div class="col-lg-6">
        <div class="card-custom" style="margin-top: 1.5rem">
          <h2 class="heading">Kontakt aufnehmen</h2>

          <p class="text-primary" style="margin-bottom: 1.5rem">
            Du hast eine Frage an uns oder möchtest uns dein Feedback mitteilen?
          </p>

          <p
            class="text-secondary"
            style="margin-bottom: 1rem; font-weight: 400"
          >
            Bitte fülle das Formular aus:
          </p>

          <ValidationObserver v-slot="{ invalid }" ref="contactForm">
            <form @submit.prevent="">
              <div style="margin-bottom: 1rem">
                <InputElement
                  v-model="formData.email"
                  placeholder="E-Mail Adresse"
                  validation-rules="required"
                ></InputElement>
              </div>

              <div style="margin-bottom: 1rem">
                <InputElement
                  v-model="formData.phoneNumber"
                  placeholder="Rufnummer"
                  validation-rules="required"
                ></InputElement>
              </div>

              <div style="margin-bottom: 1rem">
                <InputElement
                  v-model="formData.subject"
                  placeholder="Betreff"
                  validation-rules="required"
                ></InputElement>
              </div>

              <div style="margin-bottom: 1rem">
                <TextareaElement
                  v-model="formData.message"
                  placeholder="Nachricht"
                  validation-rules="required"
                  height="100px"
                ></TextareaElement>
              </div>

              <ResponseAlert
                v-if="$store.getters.errorResponse.action == 'contactForm'"
                :response="$store.getters.errorResponse.data"
                class="my-3"
              ></ResponseAlert>

              <div style="display: flex; justify-content: center">
                <ButtonElement
                  :disabled="invalid"
                  :loading="$store.getters.loading"
                  orange
                  @click="sendMessage"
                  style="width: 180px"
                >
                  Senden
                </ButtonElement>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <!-- Map -->
    <div
      class="mapouter mt-4"
      style="margin-top: 0.5rem; margin-bottom: 0.7rem"
    >
      <div class="gmap_canvas" style="border-radius: 10px">
        <iframe
          class="gmap_iframe"
          width="100%"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://www.google.com/maps/embed/v1/place?q=Vorbruch 72D, 41334 Nettetal&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/FormControl/ButtonElement.vue';
import InputElement from '../components/FormControl/InputElement.vue';
import TextareaElement from '../components/FormControl/TextareaElement.vue';

export default {
  setup() {
    return {
      formData: {
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
    };
  },
  components: {
    ButtonElement,
    InputElement,
    TextareaElement,
  },
  methods: {
    sendMessage() {
      this.invalid = false;
      this.$store.dispatch('contactForm', this.formData).then(() => {
        this.formData = {
          email: '',
          phone: '',
          subject: '',
          message: '',
        };
        this.$refs.contactForm.reset();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-container {
  height: calc(100vh - 110px);
  overflow: auto;
}

.head {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  color: #e58413;
}

.heading {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  color: #e58413;
  margin-bottom: 1rem;
}

.card-custom {
  width: 100%;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 1.5rem;
}

.text-primary {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 143.4%;
  color: #2b2b2b !important;
}

.text-secondary {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 145.4%;
  color: #bfbfbf !important;
}

.contact-info-box {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  span {
    display: inline-block;
    margin-left: 1rem;
  }
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 400px;

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 400px;
  }

  .gmap_iframe {
    height: 400px !important;
  }
}
</style>
