<template>
  <div>
    <Modal :open="isOpen" @backdropClick="isOpen = false">
      <ConfirmationModal
        @cancel="isOpen = false"
        @approve="isOpen = false"
        color-approve-button="green"
        text-approve-button="true"
      >
        Are you sure you want to remove this user? They will not be able to
        access this account anymore.
      </ConfirmationModal>
    </Modal>
    You are logged in as user: {{ $store.getters.userInfo.firstName }} with
    email: {{ $store.getters.userInfo.email }}

    <ButtonElement @click="isOpen = true">Open modal</ButtonElement>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from '@vue/composition-api';
import store from '../store';
import ButtonElement from '../components/FormControl/ButtonElement.vue';
import router from '../router';
import Modal from '../components/Modal/Modal.vue';
import ConfirmationModal from '../components/Modal/ConfirmationModal.vue';

export default {
  setup(props) {
    const isOpen = ref(false);

    return {
      isOpen,
    };
  },
  components: {
    ConfirmationModal,
    Modal,
    ButtonElement,
  },
};
</script>

<style lang="scss" scoped></style>
