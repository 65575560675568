<template>
  <div class="cart-container">
    <Loader v-if="cartLoading" class="my-5" />
    <template v-else>
      <ShoppingCart v-if="$store.getters.cartItems.length" />
      <EmptyCart v-else />
    </template>
  </div>
</template>
<script>
import ShoppingCart from '@/components/ShoppingCart/ShoppingCart';
import EmptyCart from '../components/ShoppingCart/EmptyCart';
import Loader from '@/components/Loaders/Spinner';

export default {
  components: {
    EmptyCart,
    ShoppingCart,
    Loader,
  },

  data() {
    return {
      cartLoading: false,
    };
  },

  created() {
    this.cartLoading = true;
    this.$store.dispatch('fetchCart').finally(() => {
      this.cartLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.cart-container {
  padding: 1.5rem 2rem;
  height: calc(100vh - 110px);
  overflow: auto;
}
</style>
