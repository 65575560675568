<template>
  <article class="empty-cart">
    <section class="notification-wrapper">
      <div class="title">{{ title }}</div>
      <CameraIcon height="76px" width="94px" />
      <div class="description">{{ description }}</div>
      <ButtonElement
        :orange-secondary="true"
        @click="
          $router.push({
            name: 'ProductsCategories',
          })
        "
      >
        {{ $t('enduser.products.backToShopping') }}
      </ButtonElement>
    </section>
  </article>
</template>

<script>
import CameraIcon from '../Svgs/CameraIcon';
import ButtonElement from '../FormControl/ButtonElement';
export default {
  name: 'EmptyCart',
  components: { CameraIcon, ButtonElement },
  data() {
    return {
      title: 'Your Cart',
      description:
        'Sorry, but there’s nothing to show in your cart, check some items and add them to your cart',
    };
  },
};
</script>

<style lang="scss" scoped>
.empty-cart,
.notification-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}
.empty-cart {
  height: 100%;
  text-align: center;
  width: 100%;
  .title {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 61px;
  }
  .notification-wrapper {
    flex-direction: column;
  }
  svg,
  .description {
    margin-bottom: 43px;
  }
  .description {
    color: #bfbfbf;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    max-width: 620px;
  }
}
</style>
